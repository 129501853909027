<template>
    <div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-工况.png" alt="">
                <div>调试开始后无工况报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>调试开始后</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>小时无工况，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-时间.png" alt="">
                <div>调试额定时间超标报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>超出标准值</div>
                <div class="setting-box__content__input with-unit">
                    <input type="text">
                </div>
                <div>，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-油耗.png" alt="">
                <div>调试额定油耗超标报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>超出标准值</div>
                <div class="setting-box__content__input with-unit">
                    <input type="text">
                </div>
                <div>，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-参数.png" alt="">
                <div>调试参数合规率报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>调试完成后</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>参数不合规，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-数据.png" alt="">
                <div>调试数据准确率报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>调试完成后</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>参数无工况，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-数据.png" alt="">
                <div>调试数据覆盖率报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>调试完成后</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>小时无工况，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-评分.png" alt="">
                <div>调试得分报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>调试得分低于</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>分，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-排名.png" alt="">
                <div>调试画像图排名报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>画像图排名低于</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>排名，触发报警</div>
            </div>
        </div>
        <div class="round-box setting-box">
            <div class="setting-box__header">
                <img src="../../assets/图标-彩-排名.png" alt="">
                <div>调试数据应用排名报警</div>
                <div class="setting-box__header__button">
                    <van-switch size="18px" v-model="condSwitch"></van-switch>
                </div>
            </div>
            <div class="setting-box__content">
                <div>数据应用排名低于</div>
                <div class="setting-box__content__input">
                    <input type="text">
                </div>
                <div>排名，触发报警</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            condSwitch: true,
            condVal: ""
        }
    }    
}
</script>

<style lang="scss" scoped>
    .setting-box__header{
        display: flex;
        color: #333333;
        font-size: 17px;
        align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #ebebeb;

        >img{
            margin-right: 5px;
        }

        .setting-box__header__button{
            flex-grow: 1;
            >div{
                float: right;
            }
        }
    }
    
    .setting-box__content{
        display: flex;
        font-size: 14px;
        color: #8c8c8c;
        align-items: center;

        .setting-box__content__input{
            border: 1px solid #e0e0e0;
            margin: 0 5px;
            
            &.with-unit::after{
                content: '%'
            }

            >input{
                border-radius: 5px;
                width: 70px;
                height: 30px;
                border: none;
            }
        }
    }
</style>